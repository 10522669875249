import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { FiFileText } from 'react-icons/fi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuthContext } from '../../context/Auth.context';
import { useCompanyContext } from '../../context/Company.context';
import { renderDateTime, renderDateTimeBy } from '../../utlis/cellRenders';
import { getSedarFilings, deleteFiling } from '../../api/filing';
import { Box } from '@mui/material';
import { ActionDialog } from '../../components/ActionDialog';
import { Button, Card, CardContent, CardHeader, CardTitle, Pagination, Table } from '@otcmarketsgroup/otcm-ui-lib';
import { Link } from 'react-router-dom';
import { STATUS_MAP } from '../../constants';

dayjs.extend(utc);
dayjs.extend(timezone);

const SedarFilingTable = ({ symbol }) => {
  const [authState] = useAuthContext();
  const [companyState] = useCompanyContext();
  const [page, setPage] = useState(1);
  const [dialogData, setDialog] = useState();
  const queryClient = useQueryClient();
  const user = authState.user;
  const companyId = companyState?.profile?.id;
  const isAdmin = user.isAdmin;
  const sedarFilingQuery = useQuery({
    queryKey: ['sedarFilingList', page],
    queryFn: ({ signal }) => getSedarFilings(companyId, { companyId, reportExternalSource: 'SEDAR', sortOn: 'periodDate', page, pageSize: 5 }, signal),
    retry: false,
    enabled: !!companyId
  });

  const deleteReleaseMutation = useMutation({
    mutationFn: ({ id, params }) => deleteFiling(id, params),
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['sedarFilingList', page], exact: true });
      sedarFilingQuery.refetch();
    },
    onError: (error) => {
      console.error('Error deleting item:', error);
    }
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ['sedarFilingList'] });
    };
  }, [queryClient]);

  const showPagination = sedarFilingQuery.data?.pages > 1;
  const deleteDialogContent = deleteReleaseMutation?.isSuccess ? `Your ${dialogData?.reportType} has successfully been deleted.` : `Are you sure you want to delete ${dialogData?.reportType} ${dialogData?.title} - Published ${dayjs(dialogData?.releaseDate).format('MM/DD/YYYY')}?`;

  const renderActions = data => {
    const { statusId } = data;
    const isActive = statusId === 'A';

    if (isAdmin && isActive) {
      return <Button variant='text' onClick={() => setDialog(data)}>Delete</Button>;
    }
    return null;
  };

  const allSedarFilingColumns = [
    {
      id: 'releaseDate',
      header: 'Published',
      width: isAdmin ? 200 : 'auto',
      renderCell: item => renderDateTime(item.releaseDate, isAdmin),
      admin: true,
      author: true
    },
    {
      id: 'title',
      header: 'Document',
      renderCell: item => <Link to={`/filing/content?symbol=${symbol}&id=${item.id}`} target='_blank' rel='noopener noreferrer'>
        <Button variant='text'>{item.title}</Button>
      </Link>,
      admin: true,
      author: true
    },
    {
      id: 'periodDate',
      header: 'Period End Date',
      width: isAdmin ? 125 : 'auto',
      renderCell: item => renderDateTime(item.periodDate),
      admin: true,
      author: true
    },
    {
      id: 'createdDate',
      header: 'Created',
      renderCell: item => renderDateTimeBy(item.createdDate, item.createdByUser, true),
      admin: true,
      author: true
    },
    {
      id: 'lastModifiedDate',
      header: 'Last Modified',
      renderCell: item => renderDateTimeBy(item.lastModifiedDate, item.lastModifiedUser, isAdmin),
      admin: true,
      author: false
    },
    {
      id: 'statusId',
      header: 'Status',
      width: (isAdmin) ? 100 : 'auto',
      admin: true,
      author: false,
      renderCell: item => STATUS_MAP[item.statusId]
    },
    {
      id: '',
      header: 'Action',
      width: 75,
      renderCell: item => renderActions(item),
      admin: true,
      author: false
    }
  ];

  const getFilteredColumns = (role) =>
    allSedarFilingColumns.filter((col) => col[role]);

  const sedarFilingColumns = isAdmin ? getFilteredColumns('admin') : getFilteredColumns('author');

  const handlePageClick = (e, page) => {
    setPage(page);
  };

  const handleDialogClose = () => {
    setDialog(null);
    deleteReleaseMutation.reset();
  };

  const handleDelete = () => {
    const deleteParams = {
      userId: user.id
    };
    deleteReleaseMutation.mutate({ id: dialogData.id, params: deleteParams });
  };

  return (
    <>
      <Card my={0.5}>
        <CardHeader>
          <CardTitle title='SEDAR Filings' icon={<FiFileText />} />
        </CardHeader>
        <CardContent>
          <Table sx={{ tableLayout: 'auto' }} isLoading={sedarFilingQuery.isLoading} isReloading={sedarFilingQuery.isRefetching} errorText={!!sedarFilingQuery.error?.message && 'No data available.'} columns={sedarFilingColumns} data={sedarFilingQuery.data?.records || []} />
          {showPagination && <Box display='flex' justifyContent='flex-end' pt={2}>
            <Pagination disabled={sedarFilingQuery.isLoading} page={sedarFilingQuery.data?.currentPage} count={sedarFilingQuery.data?.pages} onPageChange={handlePageClick} />
          </Box>}
        </CardContent>
      </Card>

      <ActionDialog
        open={!!dialogData}
        errorMessage={deleteReleaseMutation?.error?.message}
        isProcessing={deleteReleaseMutation.isPending}
        isComplete={deleteReleaseMutation?.isSuccess}
        title={`Delete ${dialogData?.reportType}`}
        content={deleteDialogContent}
        onClose={handleDialogClose}
        onConfirm={handleDelete}
      />
    </>
  );
};

SedarFilingTable.propTypes = {
  symbol: PropTypes.string
};

export default SedarFilingTable;
