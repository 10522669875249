import api from './api';
import queryString from 'query-string';

export async function getFilings(compId, params) {
    const response = await api.request({
        method: 'get',
        url: `/filing/company/${compId}`,
        isOTC: true,
        params
    });
    return response.data;
};

export async function getSedarFilings(compId, params) {
    const response = await api.request({
        method: 'get',
        url: `/filing/company/sedar/${compId}`,
        isOTC: true,
        params
    });
    return response.data;
};

export async function getFilingById(id) {
    const response = await api.request({
        method: 'get',
        url: `/filing/${id}`,
        isOTC: true
    });
    return response.data;
};

export async function getFilingFilters() {
    const response = await api.request({
        method: 'get',
        url: `/filing/filters`,
        isOTC: true,
    });
    return response.data;
};

export async function getFilingPEDs(compId, type) {
    const response = await api.request({
        method: 'get',
        url: `/filing/company/${compId}/ped`,
        isOTC: true,
        params: {
            type
        }
    });
    return response.data;
};

export async function postFiling(params, file) {
    let paramsTr = queryString.stringify(params);
    let formData = new FormData();
    formData.append('file', file);

    const response = await api.request({
        method: 'post',
        isOTC: true,
        url: `/filing?${paramsTr}`,
        data: formData
    });
    return response.data;

};

export async function updateFiling(id, params, file) {
    let paramsTr = queryString.stringify(params);
    let formData = new FormData();
    formData.append('file', file);

    const reqParams = {
        method: 'put',
        isOTC: true,
        url: `/filing/${id}?${paramsTr}`,
    };

    if (file) reqParams.data = formData;

    const response = await api.request(reqParams);
    return response.data;

};

export async function deleteFiling(id, params) {
    const response = await api.request({
        method: 'delete',
        isOTC: true,
        url: `/filing/${id}`,
        params: params
    });
    return response.data;

};
