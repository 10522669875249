import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ROUTES } from '../../constants/routes';
import useRouteReplacement from '../../hooks/useRouteReplacement';
import { isTodayDate, isEmptyArray, currentEstTimeZone } from '../../utlis/helper';
import { FINANCIAL_FORM_FIELDS, HELPER_TEXT, MAX_FILE_SIZE } from '../../constants';
import { reportTypeTg32, reportTypeAlci } from '../../constants/reportTypes';
import TG32Form from './TG32Form';
import { Button, Card, DateField, FileUploader, Label, TextField, TimeField, RadioGroup, SelectField, Typography } from '@otcmarketsgroup/otcm-ui-lib';

const FinancialReportForm = ({ formData, validationErrors, reportTypeOptions, pedOptions, selectedFiles, symbol, reportTypesPlaceholderText, pedPlaceholderText, disableReportType, disablePed, disablePreview, generatingPreview, isAmendment, isEdit, isAdmin, onChange, onDateTimeChange, onDateTimeChangeError, onFileSelect, onPreviewClick }) => {
    const disclosuresRoute = useRouteReplacement(ROUTES.disclosures);
    const hasFile = selectedFiles && selectedFiles.length > 0;
    const isPedOther = formData[FINANCIAL_FORM_FIELDS.PED] === FINANCIAL_FORM_FIELDS.OTHER_PED;
    const disableFields = isEdit && !isAdmin;
    const isAdminEditActive = formData?.reportStatus?.id === 'A' && isAdmin && isEdit;
    const isTG32 = formData[FINANCIAL_FORM_FIELDS.TYPE] === reportTypeTg32;
    const isReportTypeAlci = formData[FINANCIAL_FORM_FIELDS.TYPE] === reportTypeAlci;
    const emptyPedOptionForAlci = isReportTypeAlci && !disablePed && isEmptyArray(pedOptions);
    const hideFileUploader = disableFields || isTG32;
    const disableDate = formData[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'true' && !isAdminEditActive;
    const disableTime = (formData[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'true' || !formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]) && !isAdminEditActive;
    const tg32FieldsFalse = formData[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING] === 'false' || formData[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER] === 'false' || formData[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT] === 'false';
    const today = currentEstTimeZone

    const disclosureRoute = disclosuresRoute({
        ':symbol': symbol
    });

    const handleFileSelect = file => {
        onFileSelect(file);
    };

    return <>
        <Card>
            <Label>Publication Date</Label>
            {!isAdminEditActive && <RadioGroup name={FINANCIAL_FORM_FIELDS.IS_IMMEDIATE} value={formData[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE]} row onClick={onChange} options={[
                { value: true, label: 'Immediate' },
                { value: false, label: 'Custom' }
            ]} />}
            <Grid container spacing={5} sx={{ mt: 1 }}>
                <Grid size={6}>
                    <DateField
                        millisValue
                        minDate={isAdminEditActive ? undefined : today}
                        maxDate={isAdminEditActive ? today : undefined}
                        placeholder='Select Date'
                        disabled={disableDate}
                       // onError={(errorType, value) => onDateTimeChangeError(FINANCIAL_FORM_FIELDS.PUBLISH_DATE, errorType, value)}
                        error={validationErrors[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]}
                        helperText={validationErrors[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]}
                        value={formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]}
                        onSelectDate={value => onDateTimeChange(FINANCIAL_FORM_FIELDS.PUBLISH_DATE, value)} />
                </Grid>
                <Grid size={6}>
                    <TimeField
                        millisValue
                        minTime={isAdminEditActive ? undefined : isTodayDate(formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]) ? today : undefined}
                        maxTime={(isTodayDate(formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]) && isAdminEditActive) ? today : undefined}
                        placeholder='Select Time'
                        disabled={disableTime}
                        value={formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]}
                       // onError={(errorType, value) => onDateTimeChangeError(FINANCIAL_FORM_FIELDS.PUBLISH_TIME, errorType, value)}
                        error={validationErrors[FINANCIAL_FORM_FIELDS.PUBLISH_TIME]}
                        helperText={validationErrors[FINANCIAL_FORM_FIELDS.PUBLISH_TIME] || 'All times are in Eastern Time'}
                        onSelectTime={value => onDateTimeChange(FINANCIAL_FORM_FIELDS.PUBLISH_TIME, value)} />
                </Grid>
            </Grid>
            <Grid container spacing={5} sx={{ mt: 3 }}>
                {isAmendment && <Grid size={12}>
                    <TextField
                        name={FINANCIAL_FORM_FIELDS.REASON_AMEND}
                        value={formData[FINANCIAL_FORM_FIELDS.REASON_AMEND] || ''}
                        multiline
                        label='Explanatory Note'
                        placeholder='Enter Explanatory Note'
                        maxLength={500}
                        helperText={validationErrors[FINANCIAL_FORM_FIELDS.REASON_AMEND] || 'Amended Reports will be made publicly available.'}
                        showCharCounter
                        error={validationErrors[FINANCIAL_FORM_FIELDS.REASON_AMEND]}
                        disabled={(isAmendment && isEdit) || disableFields}
                        onChange={onChange}
                    />
                </Grid>}
                <Grid size={isTG32 ? 6 : 4}>
                    <SelectField
                        label='Report Type'
                        onChange={onChange}
                        groupOptions={reportTypeOptions}
                        name={FINANCIAL_FORM_FIELDS.TYPE}
                        disabled={disableReportType || disableFields}
                        value={formData[FINANCIAL_FORM_FIELDS.TYPE]}
                        placeholder={reportTypesPlaceholderText}
                    />
                </Grid>
                {!isTG32 && <Grid size={4}>
                    <TextField
                        name={FINANCIAL_FORM_FIELDS.TITLE}
                        value={formData[FINANCIAL_FORM_FIELDS.TITLE]}
                        label='Subtitle (optional)'
                        placeholder='Add Subtitle'
                        maxLength={255}
                        disabled={isAmendment || disableFields}
                        onChange={onChange}
                    />
                </Grid>}
                {!isPedOther && <Grid size={isTG32 ? 6 : 4}>
                    <SelectField
                        label={isReportTypeAlci ? 'Annual Report Period End Date' : 'Period End Date'}
                        onChange={onChange}
                        options={pedOptions}
                        name={FINANCIAL_FORM_FIELDS.PED}
                        value={formData[FINANCIAL_FORM_FIELDS.PED]}
                        error={validationErrors[FINANCIAL_FORM_FIELDS.PED]}
                        helperText={validationErrors[FINANCIAL_FORM_FIELDS.PED] || HELPER_TEXT.PED}    
                        disabled={disablePed || disableFields}
                        placeholder={pedPlaceholderText}
                    />
                </Grid>}
                {isPedOther && <Grid size={4}>
                    <DateField
                        label={isReportTypeAlci ? 'Annual Report Period End Date' : 'Period End Date'}
                        millisValue
                        maxDate={today}
                        placeholder={pedPlaceholderText}
                        disabled={disablePed || disableFields}
                        value={formData[FINANCIAL_FORM_FIELDS.OTHER_PED] || null}
                        helperText={HELPER_TEXT.PED}
                        onSelectDate={value => onDateTimeChange(FINANCIAL_FORM_FIELDS.OTHER_PED, value)} />
                </Grid>}
                {emptyPedOptionForAlci && <Typography variant='subtitle1' color='warning'>{HELPER_TEXT.ALCI_PED_EMPTY}</Typography>}
            </Grid>
            {!hideFileUploader && <Box sx={{ mt: 3 }}>
                <Box sx={{ mb: 1.5 }}>
                    <Label>{isEdit ? 'Replace File (optional)' : 'Select File'}</Label>
                </Box>
                <FileUploader
                    acceptedFileTypes={[
                        'application/pdf'
                    ]}
                    files={selectedFiles}
                    hideChoseBox={hasFile}
                    helperText={validationErrors['file']}
                    error={validationErrors['file']}
                    fileInstructions='File Requirements: PDF format only, 50 MB maximum, Not Encrypted, Not Password Protected, Not an image/scanned file'
                    maxFileSize={MAX_FILE_SIZE['50MB']}
                    onFileSelect={handleFileSelect}
                />
            </Box>}
            {isTG32 && <Box sx={{ mt: 3, pt: 3, borderTop: '1px solid var(--otc-light-gray)' }}>
                <TG32Form formData={formData} onChange={onChange} validationErrors={validationErrors} />
            </Box>}
        </Card>
        {(isTG32 && tg32FieldsFalse) && <Typography variant='subtitle1' color='warning' sx={{ mt: 2 }}>
            Certification cannot be submitted unless all eligibility criteria are met.
        </Typography>}
        <Box sx={{ textAlign: 'right', my: 3 }}>
            <Link to={disclosureRoute}>
                <Button variant='outlined'>
                    Cancel
                </Button>
            </Link>
            <Button onClick={onPreviewClick} isLoading={generatingPreview} disabled={disablePreview} endIcon={<FontAwesomeIcon icon={['fas', 'chevron-right']} />} gutterLeft>
                Preview
            </Button>
        </Box>
    </>;
};

FinancialReportForm.propTypes = {
    formData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onFileSelect: PropTypes.func.isRequired,
    onPreviewClick: PropTypes.func.isRequired,
    onDateTimeChange: PropTypes.func.isRequired,
    reportTypeOptions: PropTypes.array.isRequired,
    pedOptions: PropTypes.array.isRequired,
    onDateTimeChangeError: PropTypes.func,
    selectedFiles: PropTypes.array,
    reportTypesPlaceholderText: PropTypes.string.isRequired,
    pedPlaceholderText: PropTypes.string.isRequired,
    disableReportType: PropTypes.bool,
    disablePed: PropTypes.bool,
    isAmendment: PropTypes.bool,
    isEdit: PropTypes.bool,
    isAdmin: PropTypes.bool,
    disablePreview: PropTypes.bool,
    generatingPreview: PropTypes.bool,
    validationErrors: PropTypes.object,
    symbol: PropTypes.string.isRequired
};

export default FinancialReportForm;
