import React, { useContext, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../context/Auth.context';
import { CompanyContext } from '../context/Company.context';
import { getFilingFilters } from '../api/filing';
import { STATUS_MAP } from '../constants';
import { reportTypesGrouping, reportTypeTg32 } from '../constants/reportTypes';

export const DisclosureContext = createContext();

export const DisclosureConsumer = DisclosureContext.Consumer;

const initialState = {
  hasFilters: false,
  error: false,
  reportTypeOptions: [],
  statusOptions: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_HAS_FILTERS':
      return {
        ...state,
        hasFilters: true,
        error: false
      };
    case 'SET_REPORT_TYPES':
      return {
        ...state,
        reportTypeOptions: action.payload,
      };
    case 'SET_STATUSES':
      return {
        ...state,
        statusOptions: action.payload,
      };
    case 'SET_FILTER_ERROR':
      return {
        ...state,
        error: true,
      };
    default:
      throw new Error();
  }
};

export const DisclosureProvider = props => {
  const [authState] = useContext(AuthContext);
  const [companyState] = useContext(CompanyContext);
  const [disclosureState, disclosureDispatch] = useReducer(reducer, initialState);

  const createReportTypes = (data, profile) => {
    let rptTypes = data.reportTypes;
    if (!profile.isInternationalReporting) {
      rptTypes = rptTypes.filter(rpt => rpt.id !== reportTypeTg32)
    }

    const reportTypes = reportTypesGrouping.map(group => {
      return {
        ...group,
        options: group.options.map(option => {
          const typeOption = rptTypes.find(item => item.id === option);
          if (typeOption) {
            const optionObj = {
              id: typeOption.id,
              label: typeOption.description,
              value: typeOption.id
            };
            return optionObj;
          }
          return null;
        }).filter(option => option !== null)
      };
    });
  
    disclosureDispatch({
      type: 'SET_REPORT_TYPES',
      payload: reportTypes
    });
  };

  const createStatuses = data => {
    const allOption = { label: 'All', value: 'All' };
    // remove unknown
    const reportStatusTypes = data?.reportStatusTypes ? data.reportStatusTypes.filter(item => item.id !== "U") : [];
    const statuses = reportStatusTypes.map(item => {
      return {
        label: STATUS_MAP[item.id],
        value: item.parameter
      };
    });

    disclosureDispatch({
      type: 'SET_STATUSES',
      payload: [allOption, ...statuses]
    });
  };

  useEffect(() => {
    if (authState.isAuthenticated && !disclosureState.hasFilters && companyState.profile) {
      getFilingFilters()
        .then(data => {
          disclosureDispatch({
            type: 'SET_HAS_FILTERS'
          });
          createReportTypes(data, companyState.profile);
          createStatuses(data);
        })
        .catch(e => {
          console.error(e);
          disclosureDispatch({
            type: 'SET_FILTER_ERROR'
          });
        });
    }
  }, [disclosureState.hasFilters, authState.isAuthenticated, companyState.profile]);

  return (
    <DisclosureContext.Provider value={[disclosureState, disclosureDispatch]}>
      {props.children}
    </DisclosureContext.Provider>
  );
};

DisclosureProvider.propTypes = {
  children: PropTypes.node.isRequired
};
