import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { Link, useSearchParams  } from 'react-router-dom';
import { Box } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRouteReplacement from '../../hooks/useRouteReplacement';
import { storage, FINANCIAL_FORM_FIELDS, LOADING, TODAY_DATE, FORM_FIELD_ERROR } from '../../constants';
import { reportTypeTg32, reportTypeAlci, reportTypeAr } from '../../constants/reportTypes';
import { getFilings, getFilingById, getFilingPEDs, postFiling, updateFiling } from '../../api/filing';
import { format } from '../../utlis/locale';
import { currentEstDateObj } from '../../utlis/helper';
import getConfig from '../../config';
import { AuthContext } from '../../context/Auth.context';
import { CompanyContext } from '../../context/Company.context';
import { DisclosureContext } from '../../context/Disclosure.context';
import { withRouter } from '../../components/WithRouter';
import { ROUTES } from '../../constants/routes';
import { reportTypesAllowOtherPed } from '../../constants/reportTypes';
import { Button, Dialog, Spinner, Typography, ErrorCard } from '@otcmarketsgroup/otcm-ui-lib';
import { ConfirmationSection } from '../../components/ConfirmationSection';
import { FinancialReportForm } from '../../components/FinancialReportForm';
import { PreviewSection } from '../../components/PreviewSection';
import TemplateAmend from '../../pdfTemplates/TemplateAmend';
import Template12G32 from '../../pdfTemplates/Template12G32';
import { validateAddForm, validateAdminEditForm, validateAmendForm, validateNonAdminEditForm, validateTG32 } from './validations';
const { OTC_URL } = getConfig();
const sessionStorageToken = window.sessionStorage.getItem(storage.TOKEN);

const defaultFormData = {
  [FINANCIAL_FORM_FIELDS.TYPE]: null,
  [FINANCIAL_FORM_FIELDS.PED]: null,
  [FINANCIAL_FORM_FIELDS.TITLE]: '',
  [FINANCIAL_FORM_FIELDS.IS_IMMEDIATE]: 'true',
  [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: null,
  [FINANCIAL_FORM_FIELDS.PUBLISH_TIME]: null,
  [FINANCIAL_FORM_FIELDS.OTHER_PED]: null,
  TG32: {},
  releaseDateTime: null
};

const AddFinancialReport = ({ params, location, navigate }) => {
  const isEdit = !!params.reportId;
  const [searchParams, setSearchParams] = useSearchParams();
  const [authState] = useContext(AuthContext);
  const [companyState] = useContext(CompanyContext);
  const [disclosureState] = useContext(DisclosureContext);
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);
  const [amendOriginalData, setAmendData] = useState(null);
  const [files, setSelectedFile] = useState(null);
  const [customPdf, setCustomPdf] = useState(null);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const isAmendment = !!params.amendReportId || formData[FINANCIAL_FORM_FIELDS.IS_AMEND];
  const isPrefillForm = isAmendment || isEdit;
  const reportTypeOptions = disclosureState.reportTypeOptions;
  const [pedOptions, setPedOptions] = useState([]);
  const [renderView, setView] = useState({ loading: isPrefillForm, form: isPrefillForm ? false : true, preview: false, confirmation: false });
  const [optionsLoaded, setOptionedLoaded] = useState({ [FINANCIAL_FORM_FIELDS.TYPE]: false, [FINANCIAL_FORM_FIELDS.PED]: false });
  const [isUploading, setIsUploading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [showAmendDialog, setShowAmendDialog] = useState(false);
  const [similarFiling, setSimilarFiling] = useState(null);
  const [errors, setErrors] = useState({ reportTypeOptions: false, pedOptions: false });
  const user = authState.user;
  const profile = companyState.profile;
  const symbol = params?.symbol;
  const companyName = profile?.name;
  const isTG32 = formData[FINANCIAL_FORM_FIELDS.TYPE] && formData[FINANCIAL_FORM_FIELDS.TYPE] === reportTypeTg32;
  const isReportTypeAlci = formData[FINANCIAL_FORM_FIELDS.TYPE] && formData[FINANCIAL_FORM_FIELDS.TYPE] === reportTypeAlci;
  const isAnnualReport = formData[FINANCIAL_FORM_FIELDS.TYPE] && formData[FINANCIAL_FORM_FIELDS.TYPE] === reportTypeAr;
  const show12G32confirmation = isAnnualReport && profile?.isInternationalReporting
  const amendRoute = useRouteReplacement(ROUTES.amendFinancialReport);
  const addRoute = useRouteReplacement(ROUTES.addFinancialReport);
  const aeRoute = addRoute({
    ':symbol': symbol
  });
  const disclosuresRoute = useRouteReplacement(ROUTES.disclosures);
  const disclosureRoute = disclosuresRoute({
    ':symbol': symbol
  });
  const isLateFiling = !!queryString.parse(location?.search)?.lateFiling;
  const isAdmin = user.isAdmin;
  const companyId = profile?.id;
  const hasFile = files && files.length > 0;
  const publishDate = formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] || '';
  const isOtherPed = formData[FINANCIAL_FORM_FIELDS.PED] === FINANCIAL_FORM_FIELDS.OTHER_PED;
  const pageTitle = isLateFiling ? 'Add Notification of Late Filing' : `${isEdit ? isAdmin ? 'Edit' : 'Reschedule' : 'Add'} Financial Report${isAmendment ? ' Amendment' : ''}`;
  const reportTypesPlaceholderText = optionsLoaded[FINANCIAL_FORM_FIELDS.TYPE] ? 'Select Report Type' : errors.reportTypeOptions ? 'Error fetching options' : LOADING;
  const pedPlaceholderText = (optionsLoaded[FINANCIAL_FORM_FIELDS.PED] || !formData[FINANCIAL_FORM_FIELDS.TYPE]) ? 'Select Period End Date' : errors.pedOptions ? 'Error fetching options' : LOADING;
  const disableReportType = !optionsLoaded[FINANCIAL_FORM_FIELDS.TYPE] || isAmendment || isLateFiling;
  const disablePed = !optionsLoaded[FINANCIAL_FORM_FIELDS.PED] || !formData[FINANCIAL_FORM_FIELDS.TYPE] || isAmendment;
  const amendReportPdf = `/filing/content?symbol=${symbol}&id=${params.reportId}&token=${sessionStorageToken}`;
  const tg32FieldsFalse = formData[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING] !== 'true' || formData[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER] !== 'true' || formData[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT] !== 'true';
  const disablePreview = (isAmendment || isEdit) ? false : isTG32 ? !optionsLoaded[FINANCIAL_FORM_FIELDS.PED] || tg32FieldsFalse : (!optionsLoaded[FINANCIAL_FORM_FIELDS.SIMILAR_FILING] || !optionsLoaded[FINANCIAL_FORM_FIELDS.PED]);

  useEffect(() => {
    if (disclosureState.hasFilters) {
      setOptionedLoaded({
        ...optionsLoaded,
        [FINANCIAL_FORM_FIELDS.TYPE]: true
      });
    }
  }, [disclosureState.hasFilters]);

  useEffect(() => {
    if (disclosureState.error) {
      setErrors({
        ...errors,
        reportTypeOptions: true
      });
    }
  }, [disclosureState.error]);

  useEffect(() => {
    if (optionsLoaded[FINANCIAL_FORM_FIELDS.TYPE] && isLateFiling) {
      const NOTIFICATION_OF_LATE_FILING = 'NLF';

      setFormData((prevData) => ({
        ...prevData,
        [FINANCIAL_FORM_FIELDS.TYPE]: NOTIFICATION_OF_LATE_FILING
      }));

      handleLoadPedOptions(NOTIFICATION_OF_LATE_FILING);
    }
  }, [optionsLoaded[FINANCIAL_FORM_FIELDS.TYPE], isLateFiling]);

  useEffect(() => {
    if (optionsLoaded[FINANCIAL_FORM_FIELDS.TYPE] && isPrefillForm) {
      const reportId = isAmendment ? params.amendReportId : params.reportId;

      setView({ form: false, preview: false, confirmation: false, loading: true });

      getFilingById(reportId)
        .then(data => {
          const typeOptions = reportTypeOptions.flatMap(group => group.options);
          const reportType = typeOptions.find(option => option.id === data.reportType.id);

          let releaseDate = data?.[FINANCIAL_FORM_FIELDS.PUBLISH_DATE];
          if (releaseDate) {
            const dt = dayjs.tz(releaseDate, "America/New_York")
            const dtStr = dt.format('YYYY-MM-DD HH:mm:ss');
            releaseDate = new Date(dtStr);
          }

          setAmendData({
            [FINANCIAL_FORM_FIELDS.TITLE]: data?.[FINANCIAL_FORM_FIELDS.TITLE] || '',
            [FINANCIAL_FORM_FIELDS.TYPE]: reportType.label,
            [FINANCIAL_FORM_FIELDS.PED]: format(data.ped, data.ped, 'date'),
            [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: releaseDate,
            link: `${OTC_URL}/otcapi/company/financial-report/${data.id}/content`
          });

          setFormData((prevData) => ({
            ...prevData,
            [FINANCIAL_FORM_FIELDS.IS_AMEND]: isAmendment ? true : data?.[FINANCIAL_FORM_FIELDS.IS_AMEND],
            [FINANCIAL_FORM_FIELDS.STATUS]: data?.[FINANCIAL_FORM_FIELDS.STATUS],
            [FINANCIAL_FORM_FIELDS.REASON_AMEND]: isEdit ? data?.amendment?.reason : null,
            [FINANCIAL_FORM_FIELDS.IS_IMMEDIATE]: data[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] + '', // need to do this since API stores true vs ui 'true'
            [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: isEdit ? releaseDate : null,
            [FINANCIAL_FORM_FIELDS.PUBLISH_TIME]: isEdit ? releaseDate : null,
            [FINANCIAL_FORM_FIELDS.PED]: format(data.ped, data.ped, 'isoDate'),
            [FINANCIAL_FORM_FIELDS.TITLE]: data?.[FINANCIAL_FORM_FIELDS.TITLE] || '',
            [FINANCIAL_FORM_FIELDS.TYPE]: reportType.value
          }));

          const pedByReportType = reportType.value === '144O' ? '_144O' : reportType.value === '144P' ? '_144P' : reportType.value;
          handleLoadPedOptions(pedByReportType);

          setView({ ...renderView, form: true, loading: false });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [optionsLoaded[FINANCIAL_FORM_FIELDS.TYPE], isPrefillForm]);

  useEffect(() => {
    if (optionsLoaded[FINANCIAL_FORM_FIELDS.PED] && formData[FINANCIAL_FORM_FIELDS.PED]) {
      const isPedOther = !pedOptions.find(option => option.value === formData[FINANCIAL_FORM_FIELDS.PED]);

      if (formData[FINANCIAL_FORM_FIELDS.PED] && isPedOther) {
        setFormData((prevData) => ({
          ...prevData,
          [FINANCIAL_FORM_FIELDS.OTHER_PED]: formData[FINANCIAL_FORM_FIELDS.PED],
          [FINANCIAL_FORM_FIELDS.PED]: FINANCIAL_FORM_FIELDS.OTHER_PED,
        }));
      }
    }
  }, [optionsLoaded[FINANCIAL_FORM_FIELDS.PED]]);

  const getReportTypeObj = () => {
    const foundOption = reportTypeOptions.flatMap(group => group.options)
      .find(option => option.value === formData[FINANCIAL_FORM_FIELDS.TYPE]);

    return foundOption || {}; // Return the found option or null if not found 
  };

  const previewFormData = [
    ...(formData[FINANCIAL_FORM_FIELDS.STATUS]?.id !== 'A' && formData[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'true' ? [{
      label: 'Publication Date',
      value: `Immediately ${format(TODAY_DATE, TODAY_DATE, 'date')}`
    }] : []),
    ...(formData[FINANCIAL_FORM_FIELDS.STATUS]?.id === 'A' && isEdit ? [{
      label: 'Publication Date',
      value: `${format(publishDate, publishDate, 'dateTime')} ET`
    }] : []),
    ...(formData[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'false' && formData[FINANCIAL_FORM_FIELDS.STATUS]?.id === 'P' && isEdit ? [{
      label: 'Publication Date',
      value: `Scheduled for ${format(publishDate, publishDate, 'dateTime')} ET`
    }] : []),
    ...(formData[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'false'  && !isEdit ? [{
      label: 'Publication Date',
      value: `Scheduled for ${format(publishDate, publishDate, 'dateTime')} ET`
    }] : []),
    {
      label: 'Report Type',
      value: formData[FINANCIAL_FORM_FIELDS.TYPE] && getReportTypeObj()?.label
    },

    {
      label: 'Subtitle',
      value: formData[FINANCIAL_FORM_FIELDS.TITLE],
      hide: isTG32
    },

    {
      label: 'Explanatory Note',
      value: formData[FINANCIAL_FORM_FIELDS.REASON_AMEND],
      hide: !isAmendment
    },

    {
      label: isReportTypeAlci ? 'Annual Report Period End Date' : 'Period End Date',
      value: () => {
        let pedDisplay = formData[FINANCIAL_FORM_FIELDS.PED] && pedOptions.find(item => item.value === formData[FINANCIAL_FORM_FIELDS.PED]);
        const otherPed = formData[FINANCIAL_FORM_FIELDS.OTHER_PED];

        if (isOtherPed) pedDisplay = format(otherPed, otherPed, 'date_MM_DD_YYYY');
        if (!otherPed) pedDisplay = pedDisplay?.label;

        return pedDisplay;
      }
    },
  ];

  const generatePreviewData = () => {
    const data = previewFormData.filter(item => {
      if (item.hide) return null;
      return item;
    });

    return data;
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    const isImmediateChange = name === FINANCIAL_FORM_FIELDS.IS_IMMEDIATE;
    const isReportType = name === FINANCIAL_FORM_FIELDS.TYPE;

    if (isTG32) {
      setSelectedFile(null);
    }

    setFormData((prevData) => ({
      ...prevData,
      [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: isImmediateChange && value === 'true' ? null : prevData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE],
      [FINANCIAL_FORM_FIELDS.PUBLISH_TIME]: isImmediateChange && value === 'true' ? null : prevData[FINANCIAL_FORM_FIELDS.PUBLISH_TIME],
      [FINANCIAL_FORM_FIELDS.PED]: isReportType ? null : prevData[FINANCIAL_FORM_FIELDS.PED],
      [FINANCIAL_FORM_FIELDS.OTHER_PED]: isReportType ? null : prevData[FINANCIAL_FORM_FIELDS.OTHER_PED],
      [FINANCIAL_FORM_FIELDS.TITLE]: isTG32 ? '' : prevData[FINANCIAL_FORM_FIELDS.TITLE],
      ...(name && { [name]: type === 'checkbox' ? checked : value }),
    }));

    // reset error
    if (isImmediateChange && value === 'true') {
      setValidationErrors((prevData) => ({
        ...prevData,
        [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: null,
        [FINANCIAL_FORM_FIELDS.PUBLISH_TIME]: null
      }));
    }


    if (isReportType) {
      const pedByReportType = value === '144O' ? '_144O' : value === '144P' ? '_144P' : value;
      handleLoadPedOptions(pedByReportType);
    } else if (name === FINANCIAL_FORM_FIELDS.PED) {
      if (formData[FINANCIAL_FORM_FIELDS.TYPE] && formData[FINANCIAL_FORM_FIELDS.TYPE] !== reportTypeTg32) {
        checkSimilarFiling(value);
      }
    }
  };

  const handleLoadPedOptions = reportType => {
    setOptionedLoaded({
      ...optionsLoaded,
      [FINANCIAL_FORM_FIELDS.PED]: false
    });

    getFilingPEDs(companyId, reportType)
      .then(data => {
        const pedOptions = data.map(item => {
          return {
            label: format(item, item, 'estDate'),
            value: item
          };
        });

        setOptionedLoaded({
          ...optionsLoaded,
          [FINANCIAL_FORM_FIELDS.PED]: true
        });

        if (reportTypesAllowOtherPed.includes(reportType)) pedOptions.push({ label: 'Other', value: FINANCIAL_FORM_FIELDS.OTHER_PED });
        setPedOptions(pedOptions);
      })
      .catch(e => {
        console.error(e);
        setErrors({
          ...errors,
          pedOptions: true
        });
      });
  };

  const handleDateTimeChangeError = (name, errorType, value) => {
    if (errorType) {
      const errorText = FORM_FIELD_ERROR[errorType] || 'Invalid Data';
      setValidationErrors((prevData) => ({
        ...prevData,
        [name]: errorText
      }));
    }
    else {
      // reset it here
      setValidationErrors((prevData) => ({
        ...prevData,
        [name]: null
      }));
    }
  };

  const handleDateTimeChange = (name, value) => {
    const isDate = name === FINANCIAL_FORM_FIELDS.PUBLISH_DATE;
    const isTime = name === FINANCIAL_FORM_FIELDS.PUBLISH_TIME;

    const date = new Date(isDate ? value : formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] ? formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] : currentEstDateObj);
    const time = new Date(isTime ? value : formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] ? formData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] : currentEstDateObj);

    date.setHours(time.getHours(), time.getMinutes(), time.getUTCSeconds());

    if (name === FINANCIAL_FORM_FIELDS.PUBLISH_DATE) {
      setFormData((prevData) => ({
        ...prevData,
        [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: date.getTime()
      }));
    } else if (name === FINANCIAL_FORM_FIELDS.PUBLISH_TIME) {
      setFormData((prevData) => ({
        ...prevData,
        [FINANCIAL_FORM_FIELDS.PUBLISH_DATE]: date.getTime(),
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if(name === FINANCIAL_FORM_FIELDS.OTHER_PED){
      const pedDate = format(value, value, 'apiDate');
      checkSimilarFiling(pedDate);
    }
  };

  const handlePreviewClick = async () => {
    let errorFields = {};

    const isFormValid = isTG32 ? validateTG32(formData, errorFields) : isEdit ? isAdmin ? validateAdminEditForm(formData, errorFields) : validateNonAdminEditForm(formData, errorFields) : isAmendment ? validateAmendForm(formData, files, errorFields) : validateAddForm(formData, files, errorFields);
    setGeneratingPreview(true);

    if (isFormValid && Object.keys(errorFields).length === 0) {
      if (customPdf) setCustomPdf(null);
      if (isAmendment && hasFile) {
        let selectedFile = files[0];;

        try {
          // Create the custom PDF and convert it to a Blob
          const amendCoverBlob = await pdf(<TemplateAmend
            companyName={companyName}
            reportType={amendOriginalData[FINANCIAL_FORM_FIELDS.TYPE]}
            title={amendOriginalData[FINANCIAL_FORM_FIELDS.TITLE]}
            ped={amendOriginalData[FINANCIAL_FORM_FIELDS.PED]}
            publishDate={amendOriginalData[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]}
            reasonText={formData[FINANCIAL_FORM_FIELDS.REASON_AMEND]}
            link={amendOriginalData.link}
          />).toBlob();
          const amendPdfBytes = await amendCoverBlob.arrayBuffer();

          // Load both PDFs
          const amendPdf = await PDFDocument.load(amendPdfBytes);
          const selectedFilePdf = await PDFDocument.load(await selectedFile.arrayBuffer());

          // Create a new PDFDocument
          const mergedPdf = await PDFDocument.create();

          // Copy the cover page from the custom PDF
          const [coverPage] = await mergedPdf.copyPages(amendPdf, [0]);
          mergedPdf.addPage(coverPage);

          // Copy all pages from the selected PDF
          const selectedPageCount = selectedFilePdf.getPageCount();

          const startPage = hasFile ? 0 : 1;

          for (let i = startPage; i < selectedPageCount; i++) {
            const [selectedPdfPage] = await mergedPdf.copyPages(selectedFilePdf, [i]);
            mergedPdf.addPage(selectedPdfPage);
          }

          // Serialize the merged PDF to bytes
          const mergedPdfBytes = await mergedPdf.save();

          // Create a Blob from the merged PDF bytes
          const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

          // You can then use the mergedPdfBlob, e.g., to download it
          setCustomPdf(mergedPdfBlob);

        } catch (error) {
          console.error('Error merging PDFs:', error);
        }
      }

      if (isTG32) {
        try {
          // Create the custom PDF and convert it to a Blob
          const tg32Blob = await pdf(<Template12G32
            data={formData}
            companyName={companyName}
            symbol={symbol}
            userEmail={user?.email}
          />).toBlob();

          setCustomPdf(tg32Blob);

        } catch (error) {
          console.error('Error merging PDFs:', error);
        }
      }

      setGeneratingPreview(false);
      setPreviewView();
    } else {
      setGeneratingPreview(false);
    }
    setValidationErrors(errorFields);
  };

  const handleSubmitClick = async () => {
    const otherPed = formData[FINANCIAL_FORM_FIELDS.OTHER_PED];
    const submitParams = {
      ...formData,
      userId: user.id,
      companyId,
    };

    if (submitParams[FINANCIAL_FORM_FIELDS.PUBLISH_DATE]) submitParams.releaseDateTime = format(submitParams[FINANCIAL_FORM_FIELDS.PUBLISH_DATE], submitParams[FINANCIAL_FORM_FIELDS.PUBLISH_DATE], 'isoString');
    if (isOtherPed) submitParams[FINANCIAL_FORM_FIELDS.PED] = format(otherPed, otherPed, 'apiDate');

    if (isAmendment) submitParams[FINANCIAL_FORM_FIELDS.AMEND_REPORT_ID] = params.amendReportId;

    if (submitParams[FINANCIAL_FORM_FIELDS.TYPE] === '144O') submitParams[FINANCIAL_FORM_FIELDS.TYPE] = '_144O';
    if (submitParams[FINANCIAL_FORM_FIELDS.TYPE] === '144P') submitParams[FINANCIAL_FORM_FIELDS.TYPE] = '_144P';

    delete submitParams[FINANCIAL_FORM_FIELDS.OTHER_PED];
    delete submitParams[FINANCIAL_FORM_FIELDS.STATUS];
    delete submitParams.TG32;

    setSubmitError(false);
    setIsUploading(true);

    const fileToSubmit = customPdf ? customPdf : hasFile ? files[0] : undefined;

    if (isEdit) {
      if (formData[FINANCIAL_FORM_FIELDS.STATUS]?.id === 'A') delete submitParams[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE];
      updateFiling(params.reportId, submitParams, fileToSubmit)
        .then(data => {
          setIsUploading(false);
          setConfirmationView();
        })
        .catch(e => {
          setIsUploading(false);
          // Render error message
          const errMsg = (e?.response?.data?.message?.replace('java.lang.IllegalArgumentException: ', '') || e?.message || 'Unable to Update Filing report. Try again');
          setSubmitError(errMsg);
          console.error('E', e);
        });
    } else {
      postFiling(submitParams, fileToSubmit)
        .then(data => {
          setIsUploading(false);
          setConfirmationView();
        })
        .catch(e => {
          setIsUploading(false);
          // Render error message
          const errMsg = (e?.response?.data?.message?.replace('java.lang.IllegalArgumentException: ', '') || e?.message || 'Unable to Update Filing report. Try again');
          setSubmitError(errMsg);
          console.error('E', e);
        });
    }
  };

  const checkSimilarFiling = (ped) => {
    setOptionedLoaded({
      ...optionsLoaded,
      [FINANCIAL_FORM_FIELDS.SIMILAR_FILING]: false
    });
    const rpType = formData[FINANCIAL_FORM_FIELDS.TYPE];
    const pedByReportType = rpType === '144O' ? '_144O' : rpType === '144P' ? '_144P' : rpType;
    const getParams = {
      status: 'ACTIVE',
      reportTypes: pedByReportType,
      periodEndDate: ped
    };

    getFilings(companyId, getParams)
      .then(data => {
        if (data?.records.length > 0) {
          setSimilarFiling(data.records[0]);
          setShowAmendDialog(data.records[0]);
        }
        setOptionedLoaded({
          ...optionsLoaded,
          [FINANCIAL_FORM_FIELDS.SIMILAR_FILING]: true
        });
      })
      .catch(error => {
        setOptionedLoaded({
          ...optionsLoaded,
          [FINANCIAL_FORM_FIELDS.SIMILAR_FILING]: true
        });
      });
  };

  const setFormView = () => {
    setSubmitError(false);
    setView({ form: true, preview: false, confirmation: false, loading: false });
  };

  const resetAddFormView = () => {
    //clear out fields
    setFormData(defaultFormData);
    setSelectedFile(null);
    setCustomPdf(null);
    setAmendData(null);
    setSearchParams({});
    setView({ form: true, preview: false, confirmation: false, loading: false });
    navigate(aeRoute);
  };

  const resetAddFormViewWithReportType = (reportTypeId) => {
    resetAddFormView();
    const reportTypeChangeEvent = {
      target: {
        name: 'type',
        value: reportTypeId
      }
    }
    handleFormChange(reportTypeChangeEvent)
  };

  const setPreviewView = () => setView({ form: false, preview: true, confirmation: false, loading: false });

  const setConfirmationView = () => setView({ form: false, preview: false, confirmation: true, loading: false });

  const setAmendForm = () => {
    const route = amendRoute({
      ':symbol': symbol,
      ':amendReportId': showAmendDialog?.id,
    });

    setFormData((prevData) => ({
      ...prevData,
      [FINANCIAL_FORM_FIELDS.TITLE]: similarFiling.title || '',
      [FINANCIAL_FORM_FIELDS.AMEND_REPORT_ID]: similarFiling.id
    }));

    navigate(route);
    setShowAmendDialog(false);
  };

  return <>
    {!renderView.confirmation && <Typography variant='h1' gutterBottom sx={{ mt: 5 }}>
      {pageTitle}
    </Typography>}
    {renderView.loading && <Box
      flex='1 1'
      alignContent='center'
      textAlign='center'>
      <Spinner />
    </Box>}
    {renderView.preview && <PreviewSection
      title='Review your Financial Report details. If changes are required, select Edit.'
      note={<>
        Once you click Submit, you will not be able to edit/delete this Report.<br />
        Upload might take few minutes depending on the file size.</>}
      previewData={generatePreviewData()}
      onEdit={setFormView}
      pdfFile={customPdf ? customPdf : hasFile ? files[0] : amendReportPdf}
      disable={isUploading}
      submitErrorMsg={submitError}
      onSubmit={handleSubmitClick} />}
    {renderView.form && <FinancialReportForm
      formData={formData}
      validationErrors={validationErrors}
      onChange={handleFormChange}
      onFileSelect={setSelectedFile}
      disablePreview={disablePreview}
      generatingPreview={generatingPreview}
      onPreviewClick={handlePreviewClick}
      onDateTimeChange={handleDateTimeChange}
      onDateTimeChangeError={handleDateTimeChangeError}
      reportTypeOptions={reportTypeOptions}
      pedOptions={pedOptions}
      selectedFiles={files}
      reportTypesPlaceholderText={reportTypesPlaceholderText}
      pedPlaceholderText={pedPlaceholderText}
      disableReportType={disableReportType}
      disablePed={disablePed}
      symbol={symbol}
      isEdit={isEdit}
      isAdmin={isAdmin}
      isAmendment={isAmendment} />}
    {renderView.confirmation && <ConfirmationSection
      type={formData[FINANCIAL_FORM_FIELDS.TYPE] && getReportTypeObj().label}
      companyName={companyName}
      body='Your Financial Report has been successfully submitted. It will be available on our websites a few minutes after your selected Publication Date/Time.'>
      <Box>
        <Button endIcon={<FontAwesomeIcon icon={['fas', 'plus']} />} onClick={resetAddFormView}>
          Add Financial Report
        </Button>
        <Link to={disclosureRoute}>
          <Button endIcon={<FontAwesomeIcon icon={['fas', 'chevron-right']} />} gutterLeft>
            Continue to Finanical Reports
          </Button>
        </Link>
      </Box>
      {show12G32confirmation && <Box textAlign='left' sx={{ px: 30, mt: 2 }}>
        <ErrorCard title="Action Required:">
          <p>Please complete your 12g3-2(b) Certification next. This certification is required under OTCQX, OTCQB and OTCID Market Rules and is used to determine compliance with SEC Rule 15c2-11.</p>
          <Button endIcon={<FontAwesomeIcon icon={['fas', 'chevron-right']} />} onClick={() => resetAddFormViewWithReportType(reportTypeTg32)}>
            Complete Certification Now
          </Button>
        </ErrorCard>
      </Box>}
    </ConfirmationSection>}


    <Dialog
      open={showAmendDialog}
      actions={[
        {
          color: undefined,
          label: 'No',
          onClick: () => setShowAmendDialog(false),
          variant: 'outlined'
        },
        {
          color: undefined,
          label: 'Yes',
          onClick: setAmendForm,
          variant: 'contained'
        }
      ]}
      content={`Your company currently has an active report for ${showAmendDialog && getReportTypeObj().label} with a period end date of ${format(formData[FINANCIAL_FORM_FIELDS.PED], formData[FINANCIAL_FORM_FIELDS.PED], 'date')}. Would you like to create an amendment for this report?`}
      title='Would you like to create an amendment?'
    />
  </>;
};

AddFinancialReport.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func
};

export default withRouter(AddFinancialReport);
