import React from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const renderDateTime = (datetime, showTime) => {
    let dateString = dayjs(datetime).tz('America/New_York').format('MM/DD/YYYY');

    if (showTime) {
        dateString = <Box>
            {dayjs(datetime).tz('America/New_York').format('MM/DD/YYYY hh:mm a')} ET
        </Box>;
    }

    return dateString;
};

export const renderDateTimeBy = (date, user, showTime) => {
    const datetime = renderDateTime(date, showTime);
    const author = !user ? null : `${user?.firstName || ''} ${user?.lastName || ''}`;
    const showAuthor = !!author;

    return <>
        <div>{datetime}</div>
        {showAuthor && <div>{author}</div>}
    </>;
};
